import * as React from 'react'
import { graphql } from 'gatsby'
import { Layout, PageTitle } from '../components/layout'
import { ModularContent } from '../components/content'

const Page = ({ data }) => {
    const page = data.page

    return (
        <Layout seo={page?.seo}>
            {page?.showTitle === true ? 
                <PageTitle
                    title={page?.title}
                    featuredImage={page?.featuredImage}
                />
            : null}
            {page.modularContent?.modules &&
                <ModularContent modules={page.modularContent?.modules} />
            }
        </Layout>
    )
}

export default Page

export const query = graphql`
    query PageQuery($id: String!) {
        page: sanityPage(id: { eq: $id }) {
            title
            showTitle
            featuredImage {
                alt
                asset {
                    gatsbyImageData(
                        layout: FULL_WIDTH,
                        fit: FILL,
                    )
                    url
                }
            }
            slug {
                current
            }
            seo {
                title
                description
                image {
                    asset {
                        url
                    }
                }
            }
            modularContent {
                ...moduleContent
            }
        }
    }
`